<template>
    <div class="offers">
        <store-product 
            :data_products="data_products">
        </store-product>     

        <!-- Footer -->
        <main-footer></main-footer>     
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Super Ofertas | Industrias Pico',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
            { name: 'keywords', content:'industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
            { name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Super Ofertas | Industrias Pico'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Super Ofertas | Industrias Pico'},
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Super Ofertas | Industrias Pico'},
			{itemprop: 'description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: `
                    <img src="images/ofertas/star.svg" style="width: 40px; margin-right: 10px; margin-bottom: 0px" alt="star">
                    Super ofertas del mes
                `,
                types: [
                    { text: 'Escritorios', value: 'escritorios' },
                    { text: 'Sillas', value: 'sillas' },
                    { text: 'Combos', value: 'combos' }
                ],
                is_offers_tab: true,
                products: [
                    {
                        name: 'Rudy Alta',
                            description: `
                                <ul class="pl-3">
                                    <li>Mecanismo de 2 palancas.</li>
                                    <li>Brazo graduable.</li>
                                    <li>Pad en polipropileno.</li>
                                    <li>Base giratoria.</li>
                                    <li>5 aspas en nylon.</li>
                                    <li>Tapizada en paño murano.</li>
                                </ul>
                            `,
                        url_image: 'ofertas/sillas/oferta-rudy-alta.jpg',
                        slides: [
                            'ofertas/sillas/oferta-rudy-alta.jpg'
                        ],
                        price: '298.000',
                        url_pay: 'https://biz.payulatam.com/L0bc0a31F36464E',
                        tags: ["sillas"]
                    },
                    {
                        name: 'Escritorio Frontal',
                        description: `
                            <p>
                                Escritorio operativo frontal de diseño minimalista, con pedestales metálicos 
                                de acabado en pintura electrostática de alta resistencia a la corrosión.
                            </p>
                            <p> 
                                Superficies fabricadas en madera aglomerada RH (Resistentes a la humedad) de 15 mm.
                            </p>
                            <ul class="pl-3">
                                <li>Dimensiones: 120 x 74 x 60 cm</li>
                            </ul>
                        `,
                        url_image: 'ofertas/escritorios/escritorio.jpg',
                        slides: [
                            'ofertas/escritorio-frontal/escritorio-frontal.jpg',
                            'ofertas/escritorio-frontal/escritorio-frontal-modal.jpg',
                        ],
                        price: '298.000',
                        url_pay: 'https://biz.payulatam.com/L0bc0a31EFD0032',
                        tags: ["escritorios"]
                    },
                    {
                        name: 'Escritorio Frontal con Biblioteca rodante',
                        description: `
                            <p>
                                Escritorio frontal de diseño minimalista, con pedestales metálicos de acabado en pintura 
                                electrostática de alta resistencia a la corrosión.    
                            </p>
                            <p>Superficie y faldero elaborados en madera aglomerada RH (Resistentes a la humedad) de 15 mm.</p>
                            <ul class="pl-3">
                                <li>Dimensiones: 140 x 74 x 60 cm</li>
                            </ul>
                            <p>
                                Biblioteca rodante de 3 cajones para carga liviana y cubículo para almacenamiento de libros,
                                AZ, y demás elementos de oficina. Elaborada en madera de 15mm, y estructura metálica c20
                            </p>
                            <ul class="pl-3">
                                <li>Dimensiones: Dimensiones: 85 x 56 x 45 cm</li>
                            </ul>
                        `,
                        url_image: 'ofertas/escritorios/escritorio-biblioteca.jpg',
                        slides: [
                            'ofertas/escritorio-frontal/escritorio-frontal-biblioteca.jpg',
                            'ofertas/escritorio-frontal/escritorio-frontal-biblioteca-modal.jpg',
                        ],
                        price: '798.000',
                        url_pay: 'https://biz.payulatam.com/L0bc0a35576936D',
                        tags: ["escritorios"]
                    },
                    {
                        name: 'Silla Ergohuman',
                        description: `
                            <p class="mb-0">CUIDE SU SALUD</p>
                            <ul class="pl-3">
                                <li>Una silla diseñada 100% para cuidar su salud.</li>
                                <li>Reduce el estrés.</li>
                                <li>Evita los problemas lumbares.</li>
                            </ul>
                            <p class="mb-0">AUMENTE SU PRODUCTIVIDAD</p>
                            <p>Los factores ergonómicos que tiene aumentan un 25% su productividad.</p>
                            <p class="mb-0">FACILIDAD DE USO</p>
                            <p>Palanca Multipropósito que controla las 3 funciones principales (altura, reclinación y deslizamiento de asiento).</p>
                            <p class="mb-0">CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li>Perilla que regula la tensión de la sincronización del espaldar.</li>
                                <li>Brazo 3D, regulable en altura, ángulo y profundidad.</li>
                                <li>Palanca multiproposito que controla las 3 funciones principales de la silla (altura, reclinación, y slider).</li>
                                <li>Up and Down que permite graduar el espaldar en un rango de 6cm mediante 4 bloqueos.</li>
                            </ul>
                        `,
                        url_image: 'ofertas/escritorios/silla-ergohuman.jpg',
                        slides: [
                            'ofertas/escritorios/slider-ergohuman.jpg',
                        ],
                        price: "1.998.000",
                        url_pay: 'https://biz.payulatam.com/L0bc0a3423669D5',
                        tags: ["sillas"]
                    },
                    // {
                    //     name: 'Silla Turquía',
                    //     description: `
                    //         <p>
                    //             Rodachinas de nylon de alta resistencia, base cromada de 5 puntas, mecanismo reclinable, 
                    //             razos de Polipropileno fijo, espaldar tapizado en malla de nylon negra, 
                    //             asiento tapizado en tela malla negra.
                    //         </p>
                    //         <ul class="pl-3">
                    //             <li>Profundidad: 51 cm</li>
                    //             <li>Ancho: 50 cm</li>
                    //             <li>Alto: 51 cm</li>
                    //             <li>Ancho: 45 cm</li>
                    //             <li>Minima: 42 cm</li>
                    //             <li>Máxima: 50 cm</li>
                    //             <li>Peso máximo soportado: 120 Kg</li>
                    //         </ul>
                    //     `,
                    //     url_image: 'ofertas/escritorios/silla-turqu.jpg',
                    //     slides: [
                    //         'ofertas/escritorios/slider-turquia.jpg'
                    //     ],
                    //     price: "216.000",
                    //     url_pay: 'https://biz.payulatam.com/L0bc0a3B32CE4CF',
                    //     tags: ["sillas"]
                    // },
                    {
                        name: 'Silla Fone',
                        description: `
                            <ul class="pl-3">
                                <li>ESPALDAR</li>
                                <p>Espaldar inyectado en polipropileno, tapizado en tela de malla, regulable en altura.</p>
                                <li>ASIENTO</li>
                                <p>Asiento tapizado en tela de malla con espuma laminada.</p>
                                <li>MECANISMO</li>
                                <p>Mecanismo contacto permanente avanzado. Regulación de tensión mediante perilla. Neumático cromado con elevación a gas.</p>
                                <li>BASE</li>
                                <p>Base Cromada de 640 mm. Base Nylon de 640 mm</p>
                                <li>RUEDAS</li>
                                <p>Ruedas Goma 55 mm</p>
                                <li>BRAZOS</li>
                                <p>Brazos fijos en polipropileno. Brazos en poliuretano, regulables en altura</p>
                            </ul>
                        `,
                        url_image: 'ofertas/escritorios/silla-fon.jpg',
                        slides: [
                            'ofertas/escritorios/slider-fone.jpg'
                        ],
                        price: "287.000",
                        url_pay: 'https://biz.payulatam.com/L0bc0a339D9E543',
                        tags: ["sillas"]
                    },
                    {
                        name: 'Combo Escritorio Frontal + Silla Turquía BN',
                        description: `
                            <p>
                                Escritorio operativo frontal de diseño minimalista, con pedestales metálicos 
                                de acabado en pintura electrostática de alta resistencia a la corrosión.
                            </p>
                            <p>Superficie y faldero elaborados en madera aglomerada RH (Resistentes a la humedad) de 15 mm.</p>
                            <ul class="pl-3">
                                <li>Dimensiones: 120 x 74 x 60 cm</li>
                            </ul>
                        `,
                        url_image: 'ofertas/escritorios/escritorio-silla.jpg',
                        slides: [
                            'ofertas/escritorio-frontal/escritorio-frontal-silla.jpg'
                        ],
                        price: '476.000',
                        url_pay: 'https://biz.payulatam.com/L0bc0a364BE7ABE',
                        tags: ["combos"]
                    },
                    {
                        name: 'Combo Escritorio Frontal con Biblioteca rodante + Silla Fone',
                        description: `
                            <p>
                                Escritorio frontal de diseño minimalista, con pedestales metálicos de acabado en pintura 
                                electrostática de alta resistencia a la corrosión.    
                            </p>
                            <p>Superficie y faldero fabricados en madera aglomerada RH (Resistentes a la humedad) en madera de 15 mm.</p>
                            <ul class="pl-3">
                                <li>Dimensiones: 140 x 74 x 60 cm</li>
                            </ul>
                            <p>
                                Biblioteca rodante de 3 cajones para carga liviana y cubículo para almacenamiento de libros,
                                AZ, y demás elementos de oficina. Elaborada en madera de 15mm, y estructura metálica c20
                            </p>
                            <ul class="pl-3">
                                <li>Dimensiones: Dimensiones: 85 x 56 x 45 cm</li>
                            </ul>
                        `,
                        url_image: 'ofertas/escritorios/todo.jpg',
                        slides: [
                            'ofertas/escritorio-frontal/escritorio-frontal-biblioteca-silla.jpg'
                        ],
                        price: "990.000",
                        url_pay: 'https://biz.payulatam.com/L0bc0a39F27B678',
                        tags: ["combos"]
                    }
                ]
            }
        }
    }
}
</script>
